import React, { useEffect } from "react";
import NarrowContent from "components/NarrowContent";
import { useLoginModalStore } from "stores";
import { observer } from "mobx-react-lite";
import LoginButton from "components/login/LoginButton";
function LoginPage() {
  const loginModalStore = useLoginModalStore();
  useEffect(() => {
    loginModalStore?.open();
  }, []);
  return <NarrowContent className="marketingArea" data-sentry-element="NarrowContent" data-sentry-component="LoginPage" data-sentry-source-file="login.tsx">
      <section>
        {!loginModalStore?.isOpen && <>
            <h1 className="h1">Login</h1>
            <LoginButton />
          </>}
      </section>
    </NarrowContent>;
}
export default observer(LoginPage);