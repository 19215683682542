// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import clsx from "clsx";
import { usePageEngine } from "hooks";
import { observer } from "mobx-react-lite";
interface Props {
  children: React.ReactNode;
  className?: string;
}
function NarrowContent(props: Props) {
  const pageEngine = usePageEngine();
  let className = clsx(props.className, "NarrowContent");
  if (pageEngine.type === "themed") {
    className = className + " py-6 md:py-0";
  } else {
    className = className + " py-8 md:py-12";
  }
  const {
    children
  } = props;
  return <main data-sentry-component="NarrowContent" data-sentry-source-file="NarrowContent.tsx">
      <section className={clsx("mx-auto w-full sm:min-w-sm max-w-sm px-4 md:px-0", className)}>
        {children}
      </section>
    </main>;
}
export default observer(NarrowContent);